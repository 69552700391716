// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import { getJson, deleteJson, postJSON, postFormData } from 'components/api/request';
import { ApiRequest, ImageUrl, ProductsReqBase, ResponseBase, ResponseListBase } from 'components/api/types';
import { TOPIC_TYPE, TOPIC_SCENE, TOPIC_TAB } from 'components/constants/roadmap';

interface GetApiV3RoadmapTopicsReq extends ProductsReqBase {
  /** 类型 */
  type?: TOPIC_TYPE,
  /** 场景 */
  scene: TOPIC_SCENE,
  /** 标签 */
  tab: TOPIC_TAB,
  /** 分组ID */
  groupId?: number,
  /** 每页记录数 */
  perPage?: number,
  /** 下一页ID */
  nextPageId?: number | string,
  /** 上一页ID */
  prevPageId?: number | string,
}

export interface GetApiV3RoadmapTopicsRespData {
  id: number
  product_id: number
  title: string
  desc: any
  type: TOPIC_TYPE
  group: {
    id: number
  }
  answer_post: {
    id: string
    user_id: number
    user_avatar: string
    user_name: string
    content: string
    images: ImageUrl[]
    created_at: number
  }
  stat: {
    content_count: number
    read_count: number
    like_count: number
    like_count_init: number
  } | null
  popular_score: number
  created_at: string
  updated_at: number
  type_updated_at: number
}
interface GetApiV3RoadmapTopicSearchReq extends ProductsReqBase {
  keyword: string;
  /** 类型 */
  type?: TOPIC_TYPE,
  /** 场景 */
  scene: TOPIC_SCENE,
}


interface GetApiV3RoadmapTopicsResp extends ResponseListBase<GetApiV3RoadmapTopicsRespData> { }

export interface PostApiV3RoadmapTopicsReq extends ProductsReqBase {
  /** 标题 字数上限 100 ，同 FAQ */
  title: string;
  /** 描述 字数上限 16384 ，同 FAQ */
  desc?: string;
  /** 类型 */
  type?: TOPIC_TYPE;
  /** 分组ID 仅在type=doing时有效 */
  groupId?: number;
}
interface PostApiV3RoadmapTopicsByIdReq extends ProductsReqBase {
  /** 标题 字数上限 100 ，同 FAQ */
  title?: string;
  /** 描述 字数上限 16384 ，同 FAQ */
  desc?: string;
  /** 类型 */
  type?: TOPIC_TYPE;
  /** 分组ID 仅在type=doing时有效 */
  groupId?: number;
  topicId: number;
}


interface GetApiV3RoadmapTopicsByIdReq extends ProductsReqBase {
  /** 阅读量统计 stat_read=true 时阅读量加一。仅在需要统计的场景使用 */
  topicId?: number | string,
  /** 阅读量统计 stat_read=true 时阅读量加一。仅在需要统计的场景使用 */
  statRead?: TOPIC_TYPE,
  /** 场景 */
  scene?: TOPIC_SCENE,
}

export const getApiV3RoadmapTopics: ApiRequest<
  GetApiV3RoadmapTopicsReq,
  GetApiV3RoadmapTopicsResp
> = ({ productId, type, scene, groupId, nextPageId, perPage, prevPageId, tab }) => getJson({
  url: `/api/v3/${productId}/roadmap/topics/`,
  data: { type, scene, group_id: groupId, next_page_id: nextPageId, per_page: perPage, prev_page_id: prevPageId, tab },
});

/** 产品-Roadmap-topic - 增加 */
export const postApiV3RoadmapTopics: ApiRequest<
  PostApiV3RoadmapTopicsReq,
  ResponseBase<{ id: number }>
> = ({ productId, type, title, desc, groupId }) => postJSON({
  url: `/api/v3/${productId}/roadmap/topics/`,
  data: { type, title, desc, group_id: groupId },
});

/** 产品-Roadmap-topic - 更新*/
export const postApiV3RoadmapTopicsById: ApiRequest<
  PostApiV3RoadmapTopicsByIdReq,
  ResponseBase<{ id: number }>
> = ({ productId, type, title, desc, groupId, topicId }) => postJSON({
  url: `/api/v3/${productId}/roadmap/topics/${topicId}`,
  data: { type, title, desc, group_id: groupId },
});



/** 产品-Roadmap-topic - 获取 */
export const getApiV3RoadmapTopicById: ApiRequest<
  GetApiV3RoadmapTopicsByIdReq,
  ResponseBase<GetApiV3RoadmapTopicsRespData>
> = ({ productId, topicId, scene, statRead }) => getJson({
  url: `/api/v3/${productId}/roadmap/topics/${topicId}`,
  data: { stat_read: statRead, scene },
});


/** 产品-Roadmap-topic - 搜索列表-获取 */
export const getApiV3RoadmapTopicSearch: ApiRequest<
  GetApiV3RoadmapTopicSearchReq,
  ResponseBase<GetApiV3RoadmapTopicsRespData>
> = ({ productId, keyword, scene, type }) => getJson({
  url: `/api/v3/${productId}/roadmap/topics/search`,
  data: { keyword, type, scene, per_page: 40 },
});
