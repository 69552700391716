/* eslint-disable camelcase, react/prop-types */
import React, { Component, createRef } from 'react';
import { localStorage } from 'components/util';
import { MoreOperations } from 'components/more-operations';
import { GlobalToast } from 'components/global-toast';
import Tag from 'components/tag';
import { PostListSolution } from 'components/post-item/post-list-solution';
import { GoodQuestionPopup } from 'components/post-label';
import { Mask } from 'components/mask';
import { get_api_products_info } from 'components/api';
import { TagMobileChoose } from 'components/tag-mobile-choose';
import './post-list.mobile.less';
import { PostItem } from './post-item.mobile';
import { LoadMoreHook } from '../load-more/load-more-hook';
import { PoweredBy } from '../poweredby';
import loginChecker from 'components/util/login-checker';
import { POST_TYPE } from 'components/constants/app';
import PostAd from 'components/ad-resources/post-ad';
import { AD_INDEXES } from 'components/api/wjapi';
class PostList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmore: false, // 是否展示更多操作
      showtag: false, // 是否显示标签操作
      more_postData: {}, // 更多操作数据
      isOwner: false, // 是否是自己的帖子
      action_id: '', // 当前操作帖子、评论 id
      toast: '', // 提示信息
      isOnPageShow: false,
      showGoodQuestion: false,
      productInfo: {}, // 产品详情
      sessionUser: {}, // 用户信息
    };

    // 用户信息， 当用户有需要登录的动作才会获取
    this.userInfo = {};
    this.firstGetPostLiked = true; // 普通用户端第一次请求帖子的点赞信息时
    this.onPageShow = this.onPageShow.bind(this);

    this.postListRef = createRef();
  }

  componentDidMount() {
    window.addEventListener
      && window.addEventListener('pageshow', this.onPageShow, false);
    this.fetchProductInfo();
  }

  componentWillUnmount() {
    window.removeEventListener
      && window.removeEventListener('pageshow', this.onPageShow, false);
  }

  fetchProductInfo() {
    get_api_products_info(this.props.productId).then((res) => {
      this.setState({ productInfo: res.data });
    });
  }

  /**
   * pageshow 的事件处理
   * 移动端浏览器有 BFCache，发完帖执行 history.back 返回帖子列表的时候，列表是用 cache 中加载，页面不会触发 load 事件
   * 需要通过 pageshow 事件来获取这一行为，并动态更新数据
   * @param {PageTransitionEvent} event
   */
  onPageShow(event) {
    /* 页面不是从 cache 中加载的，正常流程 */
    if (!event.persisted) {
      return;
    }

    this.setState({ isOnPageShow: true });
  }

  /**
   * 点击更多操作
   * @param more_postData 当前帖子数据
   * @param action_id 当前操作帖子ID
   */
  toggleOperationPosts(more_postData, action_id) {
    const { state } = this;

    if (more_postData && action_id) {
      const isOwner = Boolean(more_postData.user_id === state.sessionUser.user_id)
        && state.sessionUser.is_believable;
      this.setState({
        showmore: true,
        more_postData,
        action_id,
        isOwner,
      });
      this.props.onShowMore(false);
      return;
    }

    this.props.onShowMore(state.showmore);
    this.setState({
      showmore: !state.showmore,
    });
  }

  /**
   * 举报帖子
   */
  reportPosts(reason) {
    const { state } = this;

    this.postListRef.current
      .asyncReport({
        postId: state.action_id,
        value: reason,
      })
      .then(() => {
        this.showToast('report');
      })
      .catch((err) => {
        this.showToast('BannerUploadErr', err.message.replace('<br/>', ','));
      });
    this.props.onShowMore(true);
    this.setState({ showmore: false });
  }

  /**
   * 删除帖子
   * */
  deletePosts() {
    const { state } = this;
    const postId = state.action_id;

    this.postListRef.current.asyncDeletePostOrReply(postId).then(() => {
      this.showToast('ondelete');
    });
    this.props.onShowMore(true);
    this.setState({ showmore: false });
  }

  // 帖子更多操作
  onMoreOperations({ opt, val }) {
    const { state } = this;
    const postId = state.action_id;

    if (opt === 'tag') {
      this.setState({
        showmore: false,
        showtag: true,
      });
      return;
    }

    this.postListRef.current
      .asyncOperation({ postId, opt, val })
      .then(() => {
        this.showToast(`${val}${opt}`);

        if (`${val}${opt}` === 'ongood') {
          this.firstSetGoodQuestion();
        }
        this.props.onShowMore(true);
        this.setState({ showmore: false });
      })
      .catch((err) => {
        this.showToast('BannerUploadErr', err.message.replace('<br/>', ','));
      });
  }

  // 第一次设置好问题时的提示
  firstSetGoodQuestion() {
    const isFirstSetIsGood = localStorage.getItem('isGood');

    if (!isFirstSetIsGood) {
      localStorage.setItem('isGood', 'is_good');
      this.setState({
        showGoodQuestion: true,
      });
    }
  }

  /**
   * 显示全局提示
   * @param text              提示类型，如果是错误，直接传入 Error 对象
   * @param DO_NOT_AUTO_HIDE  是否阻止自动隐藏
   */
  showToast(text, msg, DO_NOT_AUTO_HIDE = false) {
    this.setState({
      toast: text,
      errorMsg: msg,
    });

    if (!DO_NOT_AUTO_HIDE) {
      setTimeout(() => this.hideToast(), 1500);
    }
  }

  /**
   * 隐藏全局提示
   * */
  hideToast() {
    this.setState({ toast: '' });
  }

  // 获取帖子列表数据，用来请求后面的一些接口
  getPostListInfo(res) {
    console.log('getPostListInfo', res);
  }

  // 点赞帖子处理
  handleLiked(postId) {
    this.postListRef.current.asyncLiked({ postId });
  }

  /**
     * 显示\隐藏标签模块
     */
  toggleTag() {
    this.setState(preState => ({ showtag: !preState.showtag }));
  }

  // 标签选中状态更新
  changePostsList(tag, postId, isTop) {
    this.postListRef.current.asyncTagsList({ postId, isTop, tag });
  }

  render() {
    const { props, state } = this;
    return (
      <Tag.div className="post-list">
        {!props.topic && <Tag.h3 className="post-list__title">用户说</Tag.h3>}
        {state.productInfo.id && (
          <PostListSolution
            ref={this.postListRef}
            topic={props.topic}
            topicId={props.topicId}
            productId={props.productId}
            productInfo={state.productInfo}
            userData={state.sessionUser}
            isOnPageShow={state.isOnPageShow}
            getPostListInfo={this.getPostListInfo.bind(this)}
          >
            {({ noticeList, postsList, pagination, isLoading }) => {
              if (!this.postListRef.current) {
                return null;
              }
              if (this.postListRef.current.isEmptyList()) {
                return (
                  <Tag.div className="empty_data">
                    <Tag.i className="empty_data__icon" />
                    真诚的期待你的反馈…
                  </Tag.div>
                );
              }

              const renderPostItem = (item) => {
                if (item.type === POST_TYPE.AD) {
                  return (
                    <>
                      <PostAd indexes={AD_INDEXES.HOME} />
                      <PostAd indexes={AD_INDEXES.HOME2} />
                    </>
                  );
                }

                return (
                  <PostItem
                    {...item}
                    key={item.id}
                    showTopic={props.showTopic}
                    onLiked={() => {
                      this.handleLiked(item.id);
                    }}
                    onClickMore={() => {
                      loginChecker
                        .check()
                        .then((res) => {
                          this.setState({ sessionUser: res.data }, () => this.toggleOperationPosts(item, item.id));
                        });
                    }}
                    renderLink={this.state.productInfo.ugc_url_status}
                  />
                );
              };

              const isShowLoading = isLoading || pagination.has_more_pages;
              const postList = [].concat(noticeList, postsList);
              postList.splice(1, 0, { type: POST_TYPE.AD });
              return (
                <Tag.div className="post_list">
                  {!isLoading && postList.map(item => renderPostItem(item))}


                  {isShowLoading && (
                    <LoadMoreHook
                      onShow={() => this.postListRef.current.loadMore()}
                    />
                  )}
                  <PoweredBy />
                </Tag.div>
              );
            }}
          </PostListSolution>
        )}
        {/* 更多操作 */}
        <MoreOperations
          showmore={state.showmore}
          nick_name={state.more_postData.nick_name}
          is_owner={state.isOwner}
          is_admin={state.sessionUser.is_admin}
          is_top={state.more_postData.is_top}
          is_spam={state.more_postData.is_spam}
          is_hidden={state.more_postData.is_hidden}
          is_locked={state.more_postData.is_locked}
          is_reply={state.more_postData.is_reply}
          is_good={state.more_postData.is_good}
          onChange={({ opt, val }) => this.onMoreOperations({ opt, val })}
          onDelete={event => this.deletePosts()}
          onCancel={(res) => {
            loginChecker.check()
              .then((res) => {
                this.userInfo = res.data;
                this.toggleOperationPosts();
              });
          }}
          onReport={reason => this.reportPosts(reason)}
        />
        {/* 标签操作组件弹层 */}
        {state.showtag && <TagMobileChoose
          showtag={state.showtag}
          isTop={state.more_postData.is_top}
          postId={state.action_id}
          productId={this.props.productId}
          onCancel={res => this.toggleTag()}
          onError={error => this.showToast(error)}
          changePostsList={this.changePostsList.bind(this)}
        />}
        {/* 提示内容 */}
        <GlobalToast type={state.toast} text={state.errorMsg} />

        {state.showGoodQuestion && (
          <Mask
            className={'good-question-mask'}
            onClick={() => this.setState({ showGoodQuestion: false })}
          >
            <GoodQuestionPopup
              onClose={() => this.setState({ showGoodQuestion: false })}
            />
          </Mask>
        )}
      </Tag.div>
    );
  }
}

PostList.defaultProps = {
  defaultText: '真诚的期待你的反馈…',
  productId: '',
  onShowMore: () => false,
};

export { PostList };
