import xss from 'xss';
import formatTime from './formatTime';
import { saveJsonParse } from './string';

export const getDayByDate = (date) => {
  const day = new Date(date).getDay();
  return `星期${['日', '一', '二', '三', '四', '五', '六'][day]}`;
};

export const getChangeLogTitleByTimestamp = (timestamp: string | number) => {
  const time = typeof timestamp === 'string' ? parseInt(timestamp, 10) : timestamp;

  return `${formatTime(time, 'yyyy.MM.dd')} ${getDayByDate(time * 1000)}`;
};

interface Content {
  title: string;
  detail: string;
}

/** 合并内容拼接成字符串 */
export const contentToString = (content: Content[]) => content.map(item => `${item.title} ${item.detail}`).join();
export const formatChangeLogContentString = (contentString: string) => xss(contentString, {
  allowList: {}, stripIgnoreTag: true,
});

/** 获取更新日志内容 */
export const getChangeLogContent = (param) => {
  let content = [];
  // 感谢功能投票列表
  let thankVoteIdList = [];
  // 感谢帖子列表git
  let thankPostIdList = [];
  // 是否弹窗
  let notification = true;
  // 版本
  let version = '';
  // 更新时间
  let versionDate = '';
  // NOTE：目前用来存用户的感谢帖子的历史数据用来回填
  let downloadLink = [];

  const res = saveJsonParse(param, {});

  thankVoteIdList = res.thank_vote_id_list;

  notification = res.SDK_notification;
  version = res.version;
  versionDate = res.version_date;

  thankPostIdList = res.thank_post_id_list?.filter(str => !!str).map(str => saveJsonParse(str, ''));

  content = saveJsonParse(res.content, []);

  downloadLink = saveJsonParse(res.download_link, []);
  if (Array.isArray(thankVoteIdList)) {
    thankVoteIdList = thankVoteIdList.filter(item => !!item);
  }
  if (Array.isArray(thankVoteIdList)) {
    thankVoteIdList = thankVoteIdList.filter(item => !!item);
  }
  return { content, thankVoteIdList, thankPostIdList, notification, version, versionDate, downloadLink };
};


// 根据富文本内容返回第一张图片地址
export const getCoverImg = (content: string) => {
  const images = content.match(/<img(.*?)\/>/g);
  if (!images) {
    return '';
  }
  const srcList = images.map(str => str.match(/src=['"]?([^'"]*)['"]?/i)?.[1] || '');

  return srcList?.[0] || '';
};
