export const localStorage = {
    getItem(key) {
        return window.localStorage && window.localStorage.getItem(key);
    },

    setItem(key, value) {
        return window.localStorage && window.localStorage.setItem(key, value);
    }
};

export const sessionStorage = {
    getItem(key) {
        return window.sessionStorage && window.sessionStorage.getItem(key);
    },

    setItem(key, value) {
        return window.sessionStorage && window.sessionStorage.setItem(key, value);
    },
    removeItem(key) {
        return window.sessionStorage && window.sessionStorage.removeItem(key);
    },
};