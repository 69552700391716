import { getApiV1UserInfo } from 'components/api';
import getProductId from './get-product-id';
/**
 * 用户权限确认
 * 用权限返回用户信息，
 * 无权限调起对应平台相应的权限获取方式
 */
class LoginChecker {
  chekerPromise?: Promise<any>


  check = () => {
    if (!this.chekerPromise) {
      this.chekerPromise = getApiV1UserInfo({ productId: getProductId() });
    }

    return this.chekerPromise;
  }
}

const loginChecker = new LoginChecker();
export default loginChecker;
